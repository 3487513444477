import React, { FC, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IConfig } from './IConfig';

interface Props {
  darkMode: boolean;
  setDarkMode: (calendar: boolean) => void;
  config: IConfig;
  setConfig: (config: IConfig) => void;
}

export const DarkModeSwitcher: FC<Props> = ({ darkMode, setDarkMode, config, setConfig }) => {
  const switchDarkMode = (darkMode: boolean) => {
    const element = document.getElementsByTagName('html')[0];
    if (darkMode) {
      element.classList.add('dark');
      localStorage.theme = 'dark';

      const { major, minor, macro, micro } = config;
      major.color = '#ffff76';
      minor.color = '#ffff76';
      macro.color = '#ffff76';
      micro.color = '#ffff76';
      major.day = '#0004ff';
      minor.month = '#0004ff';
      macro.month = '#0004ff';
      major.highlight = '#c5c5c5';
      minor.highlight = '#c5c5c5';
      setConfig({ major, minor, macro, micro });
    } else {
      element.classList.remove('dark');
      localStorage.theme = 'light';

      const { major, minor, macro, micro } = config;
      major.color = '#add8e6';
      minor.color = '#add8e6';
      macro.color = '#add8e6';
      micro.color = '#add8e6';
      major.day = '#0004ff';
      minor.month = '#0004ff';
      macro.month = '#0004ff';
      major.highlight = '#808080';
      minor.highlight = '#808080';
      setConfig({ major, minor, macro, micro });

      setConfig({ ...config });
    }
  };

  useEffect(() => {
    setDarkMode(localStorage.theme === 'dark');
  }, []);

  useEffect(() => {
    switchDarkMode(darkMode);
  }, [darkMode]);

  return (
    <div className="text-gray-600 dark:text-gray-100 text-2xl">
      {darkMode && (
        <FontAwesomeIcon className="cursor-pointer" icon={faMoon as IconProp} onClick={() => setDarkMode(false)} />
      )}
      {!darkMode && (
        <FontAwesomeIcon className="cursor-pointer" icon={faSun as IconProp} onClick={() => setDarkMode(true)} />
      )}
    </div>
  );
};
