import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IWeatherData } from './IWeatherData';

interface Props {
  weather: boolean;
  setWeather: (weather: boolean) => void;
  weatherData?: IWeatherData;
  setWeatherData: (weatherData: IWeatherData) => void;
}

export const WeatherSwitcher: FC<Props> = ({ weather, weatherData, setWeather, setWeatherData }) => {
  const updateWeather = (weather: boolean) => {
    if (weather) {
      const lat = '53.5511';
      const long = '9.9937';
      const apiKey = '35255ed388edf050edc1f8ca0b28a5c0';
      const url = `https://api.openweathermap.org/data/2.5/weather?units=metric&lat=${lat}&lon=${long}&appid=${apiKey}`;

      if (!weatherData) {
        fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response;
          })
          .then((response) => response.json())
          .then((data) => {
            setWeatherData(data);
          })
          .catch((err) => {
            console.log(err);
            setWeatherData({
              name: 'Hamburg',
              main: { temp: 17 },
            });
          });
      }
    }

    setWeather(weather);
  };

  return (
    <div className="text-gray-600 dark:text-gray-100 text-2xl flex mt-1">
      <div className={'flex flex-col text-sm text-right mr-3 -mt-2'}>
        <span>{weather && weatherData?.name}</span>
        <span>
          {weather && weatherData?.main && Math.round(weatherData.main.temp)}
          {weatherData?.main && '°C'}
        </span>
      </div>
      <FontAwesomeIcon className="cursor-pointer" icon="cloud" onClick={() => updateWeather(!weather)} />
    </div>
  );
};
