import React, { FC } from 'react';
import { IConfig } from './IConfig';

interface Props {
  hours?: number;
  month?: number;
  config: IConfig;
}

export const Hours: FC<Props> = ({ hours, month, config }) => {
  return (
    <g>
      {[...Array(12)]
        .map((x, i) => i * 5)
        .map((x, i) => {
          let color;
          if (i % 3 === 0) {
            color = config.minor.highlight;
          } else {
            color = config.minor.color;
          }

          if ((i + 12 - 1) % 12 === month) {
            color = config.minor.month;
          }

          if (i === hours) {
            color = config.minor.hour;
          }

          return (
            <g
              key={i}
              transform={`matrix(
          1,
          0,
          0,
          1,
          ${250 - 120 * Math.cos((((2 * Math.PI) / 60) * x + Math.PI / 2) % (2 * Math.PI))},
          ${250 - 120 * Math.sin((((2 * Math.PI) / 60) * x + Math.PI / 2) % (2 * Math.PI))}
          )`}
            >
              <text
                x={`${i > 9 || i === 0 ? -26 : -13}px`}
                y={18}
                fill={color}
                style={{ font: "bold 52px 'Arial', sans-serif" }}
              >
                {i === 0 ? 12 : i}
              </text>
            </g>
          );
        })}
    </g>
  );
};
