import React, { FC, useEffect, useRef, useState } from 'react';
import { Minutes } from './Minutes';
import { Hours } from './Hours';
import { WeekDays } from './WeekDays';
import { IWeatherData } from './IWeatherData';
import { IConfig } from './IConfig';

interface Props {
  calendar?: boolean;
  weather?: boolean;
  weatherData?: IWeatherData;
  theme: 'trapez' | 'bubble';
  config: IConfig;
  className?: string;
}

export const Clock: FC<Props> = ({ calendar, weather, theme, weatherData, config, className }) => {
  const [day, setDay] = useState<number | undefined>();
  const [month, setMonth] = useState<number | undefined>();
  const [hours, setHours] = useState<number | undefined>();
  const [minutes, setMinutes] = useState<number>();
  const [seconds, setSeconds] = useState<number>();
  const [weekDay, setWeekDay] = useState<number | undefined>();

  const intervalRef = useRef<(d: Date) => void>((d: Date) => {
    setDay(d.getDate() || day);
    setMonth(d.getMonth());

    setHours(d.getHours() % 12); // => 9
    setMinutes(d.getMinutes()); // =>  30
    setSeconds(d.getSeconds()); // => 51

    setWeekDay(d.getDay()); // => 51
  });

  useEffect(() => {
    const tick: () => void = () => {
      intervalRef.current(new Date());
    };

    const interval = setInterval(tick, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <svg width="100%" height="100%" viewBox="-14 -14 528 528" version="1.1" className={className}>
      <Minutes
        minutes={minutes || 0}
        seconds={seconds || 0}
        day={day || 0}
        calendar={calendar}
        theme={theme}
        celsius={weather && weatherData ? Math.round(weatherData.main.temp) : undefined}
        config={config}
      />
      <Hours hours={hours} month={calendar ? month : undefined} config={config} />
      <WeekDays weekDay={weekDay} config={config} />
    </svg>
  );
};
