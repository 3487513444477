import { FC } from 'react';
import { Visualization } from './components/Visualization';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarDays,
  faCircle,
  faCloud,
  faGear,
  faLongArrowRight,
  faMoon,
  faSun,
} from '@fortawesome/free-solid-svg-icons';

// @ts-ignore
library.add(faCalendarDays);
// @ts-ignore
library.add(faCircle);
// @ts-ignore
library.add(faCloud);
// @ts-ignore
library.add(faGear);
// @ts-ignore
library.add(faLongArrowRight);
// @ts-ignore
library.add(faMoon);
// @ts-ignore
library.add(faSun);

export const App: FC = () => {
  return (
    <div className="App dark:bg-gray-800 min-h-screen">
      <Visualization />
    </div>
  );
};
