import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  showConfig: boolean;
  setShowConfig: (calendar: boolean) => void;
}

export const ConfigSwitcher: FC<Props> = ({ showConfig, setShowConfig }) => {
  return (
    <div className="text-gray-600 dark:text-gray-100 text-2xl">
      <FontAwesomeIcon
        className="cursor-pointer"
        icon={faGear as IconProp}
        onClick={() => setShowConfig(!showConfig)}
      />
    </div>
  );
};
