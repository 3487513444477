import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  calendar: boolean;
  setCalendar: (calendar: boolean) => void;
}

export const CalendarSwitcher: FC<Props> = ({ calendar, setCalendar }) => {
  return (
    <div className="text-gray-600 dark:text-gray-100 text-2xl">
      <FontAwesomeIcon
        className="cursor-pointer"
        icon={faCalendarDays as IconProp}
        onClick={() => setCalendar(!calendar)}
      />
    </div>
  );
};
