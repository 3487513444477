import React, { FC, useEffect, useState } from 'react';
import InputColor from 'react-input-color';
import { IConfig } from './IConfig';

interface Props {
  config: IConfig;
  setConfig: (config: IConfig) => void;
  showConfig?: boolean;
}

interface IColor {
  r: number;
  g: number;
  b: number;
  a: number;
  hex: string;
}

export const Settings: FC<Props> = ({ config, setConfig, showConfig = true }) => {
  const [color, setColor] = useState<string>(config.major.color);
  const [highlightColor, setHighlightColor] = useState<string>(config.major.highlight);
  const [activeColor, setActiveColor] = useState<string>(config.major.minute);
  const [secondColor, setSecondColor] = useState<string>(config.major.day);

  const changeColor = (color: IColor) => {
    if (color.hex) {
      const updateConfig = { ...config };
      updateConfig.major.color = color.hex;
      updateConfig.minor.color = color.hex;
      updateConfig.macro.color = color.hex;
      updateConfig.micro.color = color.hex;
      setConfig(updateConfig);
      setColor(color.hex);
    }
  };

  const changeHighlightColor = (color: IColor) => {
    if (color.hex) {
      const updateConfig = { ...config };
      updateConfig.major.highlight = color.hex;
      updateConfig.minor.highlight = color.hex;
      setConfig(updateConfig);
      setHighlightColor(color.hex);
    }
  };

  const changeActiveColor = (color: IColor) => {
    if (color.hex) {
      const updateConfig = { ...config };
      updateConfig.major.minute = color.hex;
      updateConfig.major.second = color.hex;
      updateConfig.minor.hour = color.hex;
      updateConfig.micro.weekday = color.hex;
      setConfig(updateConfig);
      setActiveColor(color.hex);
    }
  };

  const changeSecondColor = (color: IColor) => {
    if (color.hex) {
      const updateConfig = { ...config };
      updateConfig.major.day = color.hex;
      updateConfig.minor.month = color.hex;
      updateConfig.macro.month = color.hex;
      setConfig(updateConfig);
      setSecondColor(secondColor);
    }
  };

  useEffect(() => {
    changeColor({ hex: localStorage.getItem('color') } as IColor);
    changeHighlightColor({ hex: localStorage.getItem('highlightColor') } as IColor);
    changeActiveColor({ hex: localStorage.getItem('activeColor') } as IColor);
    changeSecondColor({ hex: localStorage.getItem('secondColor') } as IColor);
  }, []);

  useEffect(() => {
    localStorage.setItem('color', color);
    localStorage.setItem('highlightColor', highlightColor);
    localStorage.setItem('activeColor', activeColor);
    localStorage.setItem('secondColor', secondColor);
  }, [color, highlightColor, activeColor, secondColor]);

  if (!showConfig) {
    return <></>;
  }
  return (
    <div>
      <InputColor initialValue={color} onChange={changeColor} placement="right" />
      <InputColor initialValue={highlightColor} onChange={changeHighlightColor} placement="right" />
      <InputColor initialValue={activeColor} onChange={changeActiveColor} placement="right" />
      <InputColor initialValue={secondColor} onChange={changeSecondColor} placement="right" />
    </div>
  );
};
