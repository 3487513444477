import React, { FC } from 'react';
import { IConfig } from './IConfig';

interface Props {
  weekDay?: number;
  config: IConfig;
}

export const WeekDays: FC<Props> = ({ weekDay, config }) => {
  const radius = config.micro.radius;
  return (
    <g>
      {[...Array(6)]
        .map((x, i) => i * 10)
        .map((x, i) => {
          let color;
          if (weekDay === (i + 7 + 1) % 7) {
            color = config.micro.weekday;
          } else {
            color = config.micro.color;
          }

          return (
            <g
              key={i}
              transform={`matrix(
          1,
          0,
          0,
          1,
          ${250 - 35 * Math.cos((((2 * Math.PI) / 60) * x + Math.PI / 6 + Math.PI / 2) % (2 * Math.PI))},
          ${250 - 35 * Math.sin((((2 * Math.PI) / 60) * x + Math.PI / 6 + Math.PI / 2) % (2 * Math.PI))}
          )`}
            >
              <circle cx="0" cy="0" r={radius} fill={color} />
            </g>
          );
        })}
      <g
        transform={`matrix(
          1,
          0,
          0,
          1,
          ${250},
          ${250}
          )`}
      >
        <circle cx="0" cy="0" r={radius} fill={weekDay === 0 ? config.micro.weekday : config.micro.color} />
      </g>
    </g>
  );
};
