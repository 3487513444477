import React, { FC, useEffect, useState } from 'react';
import { Clock } from './Clock';
import { CalendarSwitcher } from './CalendarSwitcher';
import { DarkModeSwitcher } from './DarkModeSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WeatherSwitcher } from './WeatherSwitcher';
import { IWeatherData } from './IWeatherData';
import { IConfig } from './IConfig';
import { ConfigSwitcher } from './ConfigSwitcher';
import { Settings } from './Settings';

type TTheme = 'trapez' | 'bubble';

export const Visualization: FC = () => {
  const [calendar, setCalendar] = useState<boolean>(false);
  const [theme, setTheme] = useState<TTheme>('trapez');
  const [weather, setWeather] = useState<boolean>(true);
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [weatherData, setWeatherData] = useState<IWeatherData>();
  const [config, setConfig] = useState<IConfig>({
    major: {
      color: '#add8e6',
      highlight: '#808080',
      minute: '#ff0000',
      second: '#ff0000',
      day: '#0004ff',
    },
    minor: {
      color: '#add8e6',
      highlight: '#808080',
      hour: '#ff0000',
      month: '#0004ff',
    },
    macro: {
      color: '#add8e6',
      month: '#0004ff',
    },
    micro: {
      color: '#add8e6',
      weekday: '#ff0000',
      radius: 12,
    },
  });

  const changeTheme = (theme: TTheme) => {
    setTheme(theme);
  };

  useEffect(() => {
    changeTheme(localStorage.clockTheme);
  }, []);

  useEffect(() => {
    localStorage.clockTheme = theme;
  }, [theme]);

  const factoryReset = () => {
    localStorage.removeItem('clockTheme');
    localStorage.removeItem('theme');
    localStorage.removeItem('color');
    localStorage.removeItem('highlightColor');
    localStorage.removeItem('activeColor');
    localStorage.removeItem('secondColor');
    location.reload();
  };

  return (
    <main className="container mx-auto py-10 h-full px-5 text-gray-800 dark:bg-gray-800 text-gray-200">
      <div className="flex justify-between">
        <div className="text-gray-700 dark:text-gray-100">
          <h1 className="text-2xl font-bold cursor-pointer" onClick={factoryReset}>
            BimBam
          </h1>
        </div>

        <div className="flex space-x-8">
          <WeatherSwitcher
            weather={weather}
            setWeather={setWeather}
            weatherData={weatherData}
            setWeatherData={setWeatherData}
          />
          <div className="text-gray-600 dark:text-gray-100 text-2xl">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon="circle"
              onClick={() => changeTheme(theme === 'trapez' ? 'bubble' : 'trapez')}
            />
          </div>
          <CalendarSwitcher calendar={calendar} setCalendar={setCalendar} />
          <DarkModeSwitcher darkMode={darkMode} setDarkMode={setDarkMode} config={config} setConfig={setConfig} />
          <ConfigSwitcher showConfig={showConfig} setShowConfig={setShowConfig} />
        </div>
      </div>

      <div
        className="pt-20 w-full h-full mx-auto"
        style={{
          maxWidth: '550px',
        }}
      >
        <Clock
          className="mx-auto"
          calendar={calendar}
          weather={weather}
          weatherData={weatherData}
          theme={theme}
          config={config}
        />
      </div>

      <div>
        <Settings config={config} setConfig={setConfig} showConfig={showConfig} />
      </div>
    </main>
  );
};
